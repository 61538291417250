<script>
import BookingFormComponent from "@/components/Pages/Home/Booking/BookingFormComponent.vue";
import TravelHighlightComponent from "@/components/Pages/Home/TravelHighlightComponent.vue";
import ContactData from "@/components/Pages/Home/Contact/ContactData.vue";
import ControlBookingComponent from "@/components/Pages/Home/Booking/ControlBookingComponent.vue";
import ContactBookingFormComponent from "@/components/Pages/Home/Booking/ContactBookingFormComponent.vue";

export default {
  name: "HomeComponent",
  components: {
    BookingFormComponent,
    TravelHighlightComponent,
    ContactData,
    ContactBookingFormComponent,
    ControlBookingComponent,
  },
  data() {
    return {
      booking: null,
      contactBooking: null,
      finishedBooking: null
    }
  },
  created() {
    if (localStorage.getItem('bookingDetails')){
      const urlParams = new URLSearchParams(window.location.search);
      const paymentStatus = urlParams.get('payment_status');

      // Check if payment was successful or failed
      if (paymentStatus === 'success') {
        // Redirect to the payment success page
        this.$router.push('/bevestiging-rit');
      } else if (paymentStatus === 'failed') {
        // Redirect to the payment failed page
        this.$router.push('/betalings-fout');
      }
    } else {
      localStorage.removeItem("bookingDetails")
    }
  },
  methods: {
    handleFormData(data) {
      this.booking = data
    },
    handleContactData(data) {
      this.contactBooking = data
    },
    handleNullFromContactBooking(data) {
      this.booking = data
      this.contactBooking = data
    },
    handleNullFromControlBooking(data) {
      this.contactBooking = data
    }
  }
}
</script>

<template>
  <div class="content-section mt-4 mb-5">
    <div class="row">
      <!-- Left Column with Slogan -->
      <div class="col-md-6 d-flex flex-column justify-content-center">
        <h2 v-motion-slide-left class="mt-5">Kies voor gemak en betrouwbaarheid.<br> <span
            class="brand-name">Taxi4Go</span>, altijd dichtbij.</h2>
        <ul v-motion-fade-visible class="list-unstyled mt-3">
          <li class="d-flex align-items-center mb-2">
            <i class="fas fa-check me-2 text-success"></i><span>24/7 tot uw beschikking</span>
          </li>
          <li class="d-flex align-items-center mb-2">
            <i class="fas fa-check me-2 text-success"></i><span>Luchthaven vervoer</span>
          </li>
          <li class="d-flex align-items-center mb-2">
            <i class="fas fa-check me-2 text-success"></i><span>Zakelijke Taxi</span>
          </li>
          <li class="d-flex align-items-center mb-2">
            <i class="fas fa-check me-2 text-success"></i><span>Elektrisch</span>
          </li>
        </ul>
        <router-link to="/luchthaven-vervoer" class="button-route">
          <button
              type="submit"
              class="btn custom-button">
            Boek hier u rit naar de luchthaven
          </button>
        </router-link>
      </div>
      <!-- Right Column with Input Fields -->
      <div class="col-md-6">
        <div v-if="booking == null && contactBooking == null">
          <BookingFormComponent @formData="handleFormData"></BookingFormComponent>
        </div>
        <div v-if="booking != null && contactBooking == null">
          <ContactBookingFormComponent
              :booking="booking"
              @contactData="handleContactData"
              @return-null="handleNullFromContactBooking">
          </ContactBookingFormComponent>
        </div>
        <div v-if="booking != null && contactBooking != null">
          <ControlBookingComponent
              :contact-booking="contactBooking"
              @return-null="handleNullFromControlBooking">
          </ControlBookingComponent>
        </div>


      </div>
    </div>
  </div>

  <div class="secondary-content-section mt-5 mb-5">
    <div class="content-section row">
      <!-- Left Column -->
      <div class="col-md-6">
        <TravelHighlightComponent
            icon="fa-solid fa-key"
            title="Zakelijk vervoer"
            description="Bij Taxi4Go begrijpen we dat efficiëntie en professionaliteit essentieel zijn voor zakelijk vervoer. Onze op maat gemaakte vervoersoplossingen zijn ontworpen om te voldoen aan de unieke behoeften van bedrijven van elke omvang. Of het nu gaat om een belangrijke zakelijke bijeenkomst, een luchthaventransfer, of regelmatige ritten voor je personeel, wij zorgen ervoor dat je altijd op tijd en in stijl op je bestemming aankomt."
        ></TravelHighlightComponent>
      </div>
      <!-- Right Column -->
      <div class="col-md-6">
        <TravelHighlightComponent
            icon="fa-solid fa-car"
            title="Regulier vervoer"
            description="Bij Taxi4Go begrijpen we dat dagelijks vervoer niet alleen een noodzaak is, maar ook een belangrijk onderdeel van je dagelijkse routine. Daarom bieden wij een betrouwbare en comfortabele oplossing voor al je reguliere vervoersbehoeften. Of je nu een persoonlijke rit plant of een zakelijke afspraak hebt, ons regulier vervoer is de ideale keuze voor een efficiënte en aangename reiservaring van begin tot eind."
        ></TravelHighlightComponent>
      </div>
    </div>
  </div>

  <div class="content-section mt-5 mb-5">
    <div class="row">
      <!-- Left Column -->
      <div class="col-md-6 d-flex justify-content-center align-items-center">
        <h2 class="text-center">Heeft u vragen? U kunt altijd contact opnemen met Taxi4Go</h2>
      </div>
      <!-- Right Column -->
      <div class="col-md-6 d-flex justify-content-center align-items-center">
        <ContactData></ContactData>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
@import "../../../assets/css/custom";

h2{
  font-weight: bold;
  font-size: 2rem;
}


.brand-name {
  color: $primary;
}

ul {
  padding-left: 0;
}

ul li {
  font-size: 1.5rem;
  transition: padding-left 0.3s ease;
}

i {
  font-size: 1rem;
  border-radius: 50%;
  border: 2px solid black;
  padding: 1%;
  transition: padding-left 0.3s ease;
}

ul li:hover, i:hover {
  padding-left: 2%;
}

.button-route{
  width: 40%;
}

.custom-button {
  background: $primary;
  border: solid 2px $primary;
  width: 100%;
  font-size: 1.2rem;
  color: $secondary;
}

.custom-button:hover {
  background: $secondary;
  color: $primary;
  border: solid 2px $primary;
}

.content-section {
  width: 80%;
  margin: 0 auto;
}

.secondary-content-section {
  background: $secondary;
  padding: 2% 0;
}

@media only screen and (max-width: 830px) {
  .button-route{
    width: 100%;
  }
}

</style>