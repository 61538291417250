<script>
export default {
  name: "OverOnsComponent"
}
</script>

<template>
  <div class="custom-container mt-5 mb-5">
    <div class="row">
      <div class="col-md-6">
        <h2 class="mt-5">Welkom bij <span class="brand-name">Taxi4Go</span></h2>
        <p>
          Bij Taxi4Go streven we ernaar om uw vervoersbehoeften op een professionele en klantgerichte manier te vervullen. Als toonaangevende aanbieder van taxi- en vervoersdiensten begrijpen we dat elke rit uniek is en dat u alleen het allerbeste verwacht als het gaat om comfort, betrouwbaarheid en service. Wij zetten ons in om aan deze verwachtingen te voldoen en zelfs te overtreffen.<br><br>
          De ervaring van onze chauffeurs is een ander belangrijk kenmerk dat ons onderscheidt. Elk lid van ons team is zorgvuldig geselecteerd en getraind om u een veilige, comfortabele en professionele rit te bieden. Onze chauffeurs beschikken niet alleen over uitgebreide rijervaring, maar ook over een uitstekende kennis van de regio, zodat ze u efficiënt en zonder stress naar uw bestemming kunnen brengen.<br><br>
          Bij Taxi4Go draait alles om het bieden van een gepersonaliseerde ervaring. Of u nu een zakelijke rit heeft, een speciale gelegenheid viert, of gewoon op zoek bent naar een betrouwbare manier om dagelijks van A naar B te komen, wij passen onze diensten aan om aan uw specifieke wensen te voldoen. Wij geloven dat elk detail telt, van het moment dat u boekt tot het moment dat u arriveert, en daarom bieden wij flexibele boekingsopties, transparante prijsstelling en een vriendelijke klantenservice die altijd voor u klaar staat.
        </p>

      </div>
      <div class="col-md-6 flex-column mt-5">
        <img src="../../../assets/img/MercedesEQE.jpeg" alt="X" class="img-fluid custom-img">
      </div>
    </div>

    <div class="row">
      <div class="auto-section col-md-6 mt-5 mb-5">
        <h4>Over de auto</h4>
        <p>
          Bij Taxi4Go kiezen wij voor de Mercedes-Benz EQE uit het bouwjaar 2022, een voertuig dat symbool staat voor innovatie, luxe, comfort en duurzaamheid. De EQE is een toonaangevend model in de volledig elektrische reeks van Mercedes-Benz en biedt een ongeëvenaarde rijervaring die perfect aansluit bij onze hoogste standaarden van klanttevredenheid en service. <br><br>
          De Mercedes-Benz EQE uit 2022 is ontworpen met de hoogste normen van luxe en duurzaamheid in gedachten. Met zijn geavanceerde elektrische aandrijftechnologie, verfijnde interieur en premium materialen biedt deze auto een moderne en milieuvriendelijke ervaring voor elke rit. De ruime en ergonomische stoelen zijn bekleed met hoogwaardige lederen bekleding en bieden een uitstekende ondersteuning, wat zorgt voor een uiterst comfortabele rit, zelfs tijdens langere afstanden. Bovendien draagt het stille elektrische rijden bij aan een serene en ontspannen reiservaring.
        </p>
      </div>
      <div class=" col-md-6 mt-5 mb-5 ps-md-4">
        <h3>Volledig <span class="brand-name">Elektrisch</span> </h3>
        <p>
          Bij Taxi4Go hechten we veel waarde aan duurzaamheid en milieuvriendelijkheid. Daarom hebben we ervoor gekozen om elektrisch rijden centraal te stellen in onze dienstverlening. Wij geloven dat moderne mobiliteit niet alleen draait om comfort en betrouwbaarheid, maar ook om verantwoordelijkheid nemen voor het milieu en de toekomst van onze planeet. <br><br>

          Door te investeren in elektrische voertuigen, zoals de Mercedes-Benz EQE, dragen we actief bij aan het verminderen van CO₂-uitstoot en geluidsoverlast. Elektrisch rijden biedt niet alleen een schonere manier van reizen, maar zorgt ook voor een stillere en meer ontspannen rijervaring voor zowel onze passagiers als de omgeving.

          Onze keuze voor elektrisch rijden weerspiegelt onze toewijding aan innovatie en duurzaamheid. Wij zien het als onze verantwoordelijkheid om de hoogst mogelijke kwaliteit van dienstverlening te combineren met een positieve impact op het milieu. Samen met onze klanten zetten we een stap naar een groenere toekomst.
        </p>
      </div>
    </div>


    <div class="row">
      <div class="col-md-6 mb-3">
        <img src="../../../assets/img/MercedesEQEInsideCollage.jpeg" alt="Left Image" class="img-fluid rounded">
      </div>
      <div class="col-md-6 mb-3">
        <img src="../../../assets/img/MercedesEQEOutsideCollage.jpeg" alt="Right Image" class="img-fluid rounded">
      </div>
    </div>

    <h3>Over de chauffeur</h3>
    <p class="mb-5">
      Bij Taxi4Go begrijpen we dat de chauffeur een cruciale rol speelt in de kwaliteit van uw rit. Daarom kiezen wij alleen de allerbeste chauffeurs die niet alleen beschikken over een gedegen rijervaring, maar ook een diepgeworteld gevoel van professionaliteit en betrouwbaarheid met zich meebrengen. Onze chauffeurs zijn volledig gekwalificeerd en getraind om u de hoogste standaard van service te bieden, ongeacht de bestemming of het type rit.<br><br>
      Onze chauffeurs zijn meer dan alleen professionele bestuurders; zij zijn ook gastheeren met een warme en vriendelijke benadering. Ze zijn altijd bereid om een stapje extra te zetten om uw reis zo aangenaam mogelijk te maken. Of u nu een vraag heeft over uw route, speciale verzoeken hebt of gewoon behoefte heeft aan een vriendelijk gesprek, onze chauffeurs staan klaar om te helpen met een glimlach.<br><br>
      Veiligheid is een topprioriteit bij Taxi4Go, en onze chauffeurs spelen hierin een sleutelrol. Ze zijn getraind in defensief rijgedrag en maken gebruik van de nieuwste technologieën en veiligheidssystemen in onze voertuigen om een veilige rit te waarborgen. Elk van onze chauffeurs volgt strikte richtlijnen om ervoor te zorgen dat uw reis soepel en zonder incidenten verloopt. U kunt erop vertrouwen dat u in goede handen bent.
    </p>

  </div>

</template>

<style scoped lang="scss">
@import "../../../assets/css/custom";

.custom-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

.brand-name {
  color: $primary;
}

p {
  font-size: 1rem; // Adjust the font size as needed
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.custom-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%; // Adjust this value to make the image smaller
}

h3 {
  font-weight: bold;
  font-size: 1.5rem; // Adjust the font size as needed
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid $primary; // Add a horizontal line under the h3
}

h3 + p {
  margin-top: 10px; // Add spacing between h3 and p
}

.auto-section{
  background: $primary;
  color: #fff;
  padding: 1%;
  border: 2px solid $accent;
}

.auto-section h4{
  font-weight: bold;
}

</style>